import React  from 'react';
import { useLocation } from "react-router-dom";

import Header from "../../components/header/Header";
import FileUploader from "../../components/uploader/FileUploader";
import Footer from "../../components/footer/Footer";

import AdsDisplay from "../../components/ads/AdsDisplay";

function FileUploaderPage(){

  const location = useLocation();

  return(
    <div>
      <Header/>
      <header class="masthead">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-lg-6">
                <p align="center">
                  <AdsDisplay dataAdSlot='8623204473'/>
                </p>
                <p align="center">
                    <h4>
                          Convert file from {(location.state.fileFrom).replace('.','').toUpperCase()} to {(location.state.fileTo).replace('.','').toUpperCase()}
                    </h4>      
                </p>
                <p>
                    <FileUploader fileFrom={location.state.fileFrom} fileTo={location.state.fileTo}/>
                </p>
                <p align="center">
                  <AdsDisplay dataAdSlot='9677265849' />
                </p>
            </div>
          </div>
        </div>
      </header>
      <Footer/>    
    </div>
  ) 
};

export default FileUploaderPage;