import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

function PDFCard(props){

    const navigate = useNavigate();

    const handleTranform = () => {

        //window.alert((props.fileFrom + ' ' + props.fileTo));

        let params = {fileFrom: props.fileFrom, fileTo: props.fileTo};
        
        navigate('/uploder',{state:params});
    }

    return(
                    
        <Card border="primary" style={{ width: '18rem', cursor:'pointer'}}  >
            <div onClick={handleTranform}>
            <Card.Body>
            <Card.Title>{props.title}</Card.Title>
            <Card.Text>
                {props.description}
            </Card.Text>
            </Card.Body>
            </div>
        </Card>
    
    )
};




export default PDFCard;