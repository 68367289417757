import React from 'react';

import PDFCard from "../../components/card/PDFCard";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import AdsDisplay from "../../components/ads/AdsDisplay";


function HomePage(){
      return(
        <div>
          <Header/>
          <header class="masthead">
            <div class="container">
              <div class="row justify-content-md">
                <div>
                    <p align="center">
                      <AdsDisplay dataAdSlot='8623204473'/>
                    </p>
                    <p align="center">
                        <h4>Convert to PDF</h4>
                    </p>
                </div>
                <div class="row justify-content-md">
                    <PDFCard title="PPTX to PDF" description="Convert Power Point Document (PPTX) to PDF" fileFrom=".pptx" fileTo=".pdf" />
                    <PDFCard title="PPT to PDF" description="Convert Power Point Document (PPT) to PDF" fileFrom=".ppt" fileTo=".pdf" />
                    <PDFCard title="DOCX to PDF" description="Convert Word Document (DOCX) to PDF" fileFrom=".docx" fileTo=".pdf" />
                    <PDFCard title="DOC to PDF" description="Convert Word Document (DOC) to PDF" fileFrom=".doc" fileTo=".pdf" />
                    <PDFCard title="JPG to PDF" description="Convert JPEG Image (JPG) to PDF" fileFrom=".jpg" fileTo=".pdf" />
                </div>
              </div>
              <p align="center">
                  <AdsDisplay dataAdSlot='9677265849' />
                </p>
            </div>
          </header>
          <Footer />
        </div>
      )
};

export default HomePage;