import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

function Footer(props) {
  return (
    <Navbar fixed="bottom" className="bg-black py-3">
        <Container className="px-5 justify-content-center">
            <div class="text-white-50 small">
              <p align="center">
                <div class="mb-2">&copy; OpenServices.Me 2024. All Rights Reserved.</div>
                <a href="#!">Privacy</a>
                <span class="mx-1">&middot;</span>
                <a href="#!">Terms</a>
                <span class="mx-1">&middot;</span>
                <a href="#!">FAQ</a>
              </p>
            </div>
        </Container>
    </Navbar>
  );
}

export default Footer;