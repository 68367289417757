import React, { useEffect  } from 'react';

const AdsDisplay = (props) => {
    const { dataAdSlot } = props;  

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
        catch (e) {

        }

    },[]);

    return (
        <>
            {dataAdSlot}
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4283520760275296"
     crossorigin="anonymous"></script>
            <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-4283520760275296"
                data-ad-slot={dataAdSlot}
                data-ad-format="auto"
                data-full-width-responsive="true">
            </ins>
        </>
    );
};

export default AdsDisplay;