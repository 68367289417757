import Carousel from 'react-bootstrap/Carousel';


function HomeCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <h4 class="display-1 lh-1 mb-3">Enjoy Openservices.ME - Platform to convert your PDF files. Everthing Free!</h4>
        <p class="lead fw-normal text-muted mb-5">A productivity platform to convert different files into PDF format.</p>
      </Carousel.Item>
      <Carousel.Item>
        <h4 class="display-1 lh-1 mb-3">More than 10.000 files converted!</h4>
        <p class="lead fw-normal text-muted mb-5">Users are loving our simplificity! Enjoy our tool, it's free!</p>
      </Carousel.Item>
      <Carousel.Item>
        <h4 class="display-1 lh-1 mb-3">More features coming soon!</h4>
        <p class="lead fw-normal text-muted mb-5">We are working to add new files formats and types of conversion. Get ready, new features are coming soon.</p>
      </Carousel.Item>
    </Carousel>
  );
}


export default HomeCarousel;