import React  from 'react';
import { useLocation } from "react-router-dom";

import Header from "../../components/header/Header";
import FileDownloader from "../../components/uploader/FileDownloader"
import Footer from "../../components/footer/Footer";

import AdsDisplay from "../../components/ads/AdsDisplay";

function FileUploaderPage(){

  const location = useLocation();

  return(
    <div>
      <Header showAds="true"/>
      <header class="masthead">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-lg-6">
                <p align="center">
                  <AdsDisplay dataAdSlot='8623204473'/>
                </p>
                <p>
                    <FileDownloader sessionId={location.state.sessionId} operationId={location.state.operationId} fileName={location.state.fileName} fileLocation={location.state.fileLocation} />
                </p>
                <p align="center">
                  <AdsDisplay dataAdSlot='9677265849' />
                </p>
            </div>
          </div>
        </div>
      </header>
      <Footer/>    
    </div>
  ) 
};

export default FileUploaderPage;