import React, { useState} from 'react';
import axios from "axios";

import { useNavigate } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';

import {getSessionCookie} from '../cookie/Cookie';


function FileUploader(props){

  const [file, setFile] = useState(null);
  const [progressBar, setProgressBar] = useState(0);
  
  const navigate = useNavigate();

  const handleFileChange = (event) =>  {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }

  };

  const handleUpload = async () => {

    const clientId = process.env.REACT_APP_CLIENT_ID;
    const xTokenID = process.env.REACT_APP_TOKEN_ID;
    const uploadURL = process.env.REACT_APP_UPLOAD_URL;

    if (file) {
      console.log('Uploading file...');

      let cookieSession = getSessionCookie();
      
      let operationId = (props.fileFrom + '-' + props.fileTo).replaceAll('.','');

      const formData = new FormData();
      formData.append('file', file);
      formData.append('operationId', operationId);
      formData.append('sessionId', cookieSession);
      formData.append('clientId', clientId);
  
      let data = '';

      await axios.post(uploadURL,
        formData,
        {headers: {"X-TokenID": xTokenID},
        onUploadProgress: (progressEvent) =>{
          let {loaded, total} = progressEvent;
          let perc = Math.round((100 * loaded) / total);

          setProgressBar(perc)
        }}
      )
      .then((response) => {
        data = response.data;

        let fileLocation = data.fileLocation;

        let fileInfo = {sessionId: cookieSession,
                        operationId: operationId,
                        fileName: file.name,
                        fileLocation: fileLocation};

        navigate('/download',{state:fileInfo});

      })
      .catch((error) =>{
        console.error(error);
        window.alert(JSON.stringify(error));
      })

    }
  };

    return(
      <div class="row justify-content-md-center">
          
            <Form.Group controlId="formFile" className="mb-3">
              
              <Form.Control id="file" type="file" onChange={handleFileChange} accept={props.fileFrom} />
            </Form.Group>
                                      
            { file  && 
              <Button variant="primary" size="lg" onClick={handleUpload} >
                Upload file
              </Button>
            }
            
            { progressBar > 0 &&
              <div class="row justify-content-md-center">
                <p>&nbsp;</p>
                Loading ...
                <ProgressBar now={progressBar} label={`${progressBar}%`} />  
              </div>
            }

      </div>
            
    )
};


export default FileUploader;