import React from 'react';


import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import HomeCarousel from "../../components/carousels/HomeCarousel";

function HomePage(){
      return(
        <div>
          <Header/>
          <header class="masthead">
            <div class="container">
              <div class="row justify-content-md">                
                <p align="center">
                  <HomeCarousel />
                </p>
              </div>
            </div>
          </header>
          <Footer />
        </div>
      )
};

export default HomePage;