import React from 'react';

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

function ConvertImages() {
    
    return(
      <div>
        <Header />
        <h1>Convert Images </h1>
        <Footer />
      </div>
    )

};

export default ConvertImages;