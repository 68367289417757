import { v4 as uuid } from 'uuid'
    
export default function Cookie(props){

}
      
export function newCookie(name,value) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 10);

        document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
}

export function getCookie(name){
        let cookies = document.cookie.split("; ").find((row) => row.startsWith(`${name}=`));

        return cookies ? cookies.split("=")[1] : null;
}

export function deleteCookie(name){
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

export function getSessionCookie(){

        const cookieName = process.env.REACT_APP_COOKIE_SESSION;

        let cookie = getCookie(cookieName);

        if (cookie === null){
            newCookie(cookieName,uuid());
            cookie = getCookie(cookieName);
        }

        return cookie;
}