import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

function TopMenu(){
    return (
        <Navbar expand="lg" className="navbar-light fixed-top shadow-sm">
            <Container className="px-5">
                <Navbar.Brand className="navbar-brand fw-bold" href="/">OpenServices.me</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" variant="underline">
                        <Nav.Item className="navbar-nav ms-auto me-4 my-3 my-lg-0"> 
                            <Nav.Link className="nav-item me-lg-3" href="/pdf">Convert PDF</Nav.Link>
                            
                            <Nav.Link className="nav-item me-lg-3" href="/about">About</Nav.Link>
                            <Nav.Link className="nav-item me-lg-3" href="/contact">Contact</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
 }

export default TopMenu;