import { BrowserRouter, Routes, Route} from "react-router-dom";

import TopMenu from "./components/menu/TopMenu";


import HomePage from "./pages/home/HomePage";
import HomePDFPage from "./pages/pdf/HomePDFPage";
import ConvertImagesPage from "./pages/images/ConvertImagesPage";
import AboutPage from "./pages/about/AboutPage";
import ContactPage from "./pages/contact/ContactPage";

import UploaderPage from "./pages/file/UploaderPage";
import DownloaderPage from "./pages/file/DownloaderPage";

import NoPage from "./pages/others/NoPage";

function App() {
  return (
      <div>
        <BrowserRouter>
          <TopMenu/>
          <Routes>
              <Route path="/" element={<HomePage />} />  
              <Route path="/pdf" element={<HomePDFPage />} />
              <Route path="/img" element={<ConvertImagesPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/uploder" element={<UploaderPage />} />
              <Route path="/download" element={<DownloaderPage />} />
              <Route path="*" element={<NoPage />} />
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
