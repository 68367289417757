import React, {useEffect, useState } from 'react';
import axios from "axios";

function FileDownloader(props){

  const [isTransformed,setIsTransformed] = useState(false);
  const [fullFileName,setFullFileName] = useState(null);

  const state = {
    fileName: props.fileName,
    sessionId: props.sessionId,
    operationId: props.operationId,
    transformedFile: null
  };

  const handleDownloadFile = async () => {
  
    const downloadURL = process.env.REACT_APP_DOWNLOAD_URL;

    let xFile = null
    
    if(state.transformedFile){
      xFile = state.transformedFile
    }else{
      xFile =fullFileName 
    }

    let bodyJSON = {
      clientId: process.env.REACT_APP_CLIENT_ID,
      sessionId: state.sessionId,
      fileLocation: xFile
    }

    let fName = bodyJSON.fileLocation.substring(bodyJSON.fileLocation.lastIndexOf('/')+1,bodyJSON.fileLocation.length)

    let data = '';

    await axios.post(downloadURL,
                     bodyJSON,
                    { responseType: 'blob',
                      headers: {"X-TokenID": process.env.REACT_APP_TOKEN_ID}}
    )
    .then((response) => {
        data = response.data;

        const url = window.URL.createObjectURL(new Blob([data]),{type: data.type});
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fName,
        );
    
        document.body.appendChild(link);
    
        link.click();
    
        link.parentNode.removeChild(link);
    })
    .catch((error) =>{
      console.error(error);
      window.alert(JSON.stringify(error));
    })


  };

  const handleTransform = async () => {

    if(isTransformed === false){

      const tranformURL = process.env.REACT_APP_TRANSFORM_URL;
  
      let bodyJSON = {
        clientId: process.env.REACT_APP_CLIENT_ID,
        sessionId: state.sessionId,
        operationId: state.operationId,
        fileName: state.fileName,
        url:tranformURL
      }
  
      let data = '';

      await axios.post(tranformURL,
                       bodyJSON,
                      {headers: {"X-TokenID": process.env.REACT_APP_TOKEN_ID}}
      )
      .then((response) => {
  
          data = response.data;
          state.transformedFile = data.fileLocation;

          setFullFileName(state.transformedFile);
          handleDownloadFile();
      })
      .catch((error) =>{
        console.error(error);
        window.alert(JSON.stringify(error));
      })
  
      setIsTransformed(true);

    }
  
  }

  useEffect(() => {
    handleTransform();

  }, []);

    return(
        <div>
          { !isTransformed &&
            <p align="center">
                <h4>
                    Processing file conversion {state.fileName} 
                </h4>      
  
                <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" alt=""/>
            </p>
          }
          { isTransformed &&
              <p align="center">
                  <h4>
                      The file {state.fileName} has been converted sucessfully.
                  </h4>   
                  &nbsp;   
                  <h6>In case the file does not download automatically, use the "Download" button.</h6>
                  &nbsp;
                  &nbsp;
                  
                 <button type="button" class="btn btn-primary" onClick={handleDownloadFile}>Download</button>
              </p>
          }
          
        </div>
    )
};

export default FileDownloader;