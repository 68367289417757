import React from 'react';

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

function NoPage() {
  return(
    <div>
      <Header />
      <div class="masthead">
        <div class="container px-10">
            <div class="row align-items-center">
                <p align="center">
                  <i class="bi-bug icon-feature d-block mb-3"></i>
                  <h1>Page not found!</h1>
                </p>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  )
};



export default NoPage;